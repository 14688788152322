// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\casto\\alphaskymake\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-tsx": () => import("C:\\Users\\casto\\alphaskymake\\src\\templates\\blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-tags-page-tsx": () => import("C:\\Users\\casto\\alphaskymake\\src\\templates\\tags-page.tsx" /* webpackChunkName: "component---src-templates-tags-page-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("C:\\Users\\casto\\alphaskymake\\src\\templates\\blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-pages-404-tsx": () => import("C:\\Users\\casto\\alphaskymake\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("C:\\Users\\casto\\alphaskymake\\src\\pages\\about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("C:\\Users\\casto\\alphaskymake\\src\\pages\\blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\Users\\casto\\alphaskymake\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-walkthrough-tsx": () => import("C:\\Users\\casto\\alphaskymake\\src\\pages\\walkthrough.tsx" /* webpackChunkName: "component---src-pages-walkthrough-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\casto\\alphaskymake\\.cache\\data.json")

